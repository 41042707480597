<template>
  <ChangeRequestsTableLegendFrame>
    <template v-slot:colOne>
      {{ $t("global.franchisee") }}
    </template>
    <template v-slot:colTwo>
      {{ $t("global.country") }}
    </template>
    <template v-slot:colThree>
      {{ $t("components.menus.votingEnds") }}
    </template>
    <template v-slot:colFour>
      {{ $t("global.type") }}
    </template>
    <template v-slot:colFive>
      {{ $t("global.status") }}
    </template>
  </ChangeRequestsTableLegendFrame>
</template>

<script>
import ChangeRequestsTableLegendFrame from "@/components/menus/change-requests/ChangeRequestsTableLegendFrame";

export default {
  components: {
    ChangeRequestsTableLegendFrame,
  },
};
</script>
