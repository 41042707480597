<template>
  <div class="sm:container">
    <div v-if="error">
      {{ error }}
    </div>
    <TableFrame>
      <TableHeader>
        <template v-slot:title>
          {{ $t("components.menus.changeRequestsTitle") }}
        </template>
        <template v-slot:description>
          {{ $t("components.menus.changeRequestsDescription") }}
        </template>
      </TableHeader>
      <div v-if="sortedChangeRequests.length > 0">
        <ChangeRequestsTableLegend />
        <ChangeRequestsTableRow
          v-for="changeRequest in sortedChangeRequests"
          :key="changeRequest.index"
          :change-request="changeRequest"
          @click="openReviewModal(changeRequest)"
        />
      </div>
      <div
        v-else
        class="border-t border-neutral-50 bg-neutral-0 p-5 text-center text-sm text-neutral-400"
      >
        {{ $t("components.menus.noChangeRequests") }}
      </div>
    </TableFrame>

    <ChangeRequestReviewModal
      v-if="reviewModalOpen"
      :change-request="focusedChangeRequest"
      @close="closeModal"
    />
  </div>
</template>

<script>
import TableFrame from "@/components/common/table/TableFrame";
import TableHeader from "@/components/common/table/TableHeader";
import ChangeRequestsTableLegend from "@/components/menus/change-requests/ChangeRequestsTableLegend";
import ChangeRequestsTableRow from "@/components/menus/change-requests/ChangeRequestsTableRow";
import ChangeRequestReviewModal from "@/components/menus/change-requests/ChangeRequestReviewModal";

import store from "@/store";
import { mapGetters } from "vuex";

export default {
  name: "ChangeRequests",

  components: {
    TableFrame,
    TableHeader,
    ChangeRequestsTableLegend,
    ChangeRequestsTableRow,
    ChangeRequestReviewModal,
  },

  beforeRouteEnter(_to, _from, next) {
    Promise.all([store.dispatch("changeRequests/fetchChangeRequests")]).finally(
      () => {
        next();
      },
    );
  },

  data() {
    return {
      reviewModalOpen: false,
      focusedChangeRequest: null,
    };
  },

  computed: {
    ...mapGetters("changeRequests", ["sortedChangeRequests", "error"]),
  },

  methods: {
    openReviewModal(changeRequest) {
      this.focusedChangeRequest = changeRequest;
      this.reviewModalOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    closeModal() {
      this.reviewModalOpen = false;
      document.body.classList.remove("overflow-y-hidden");
    },
  },
};
</script>
