<template>
  <ChangeRequestsTableRowFrame @click="emitClick">
    <template v-slot:colOne>
      <div class="relative flex items-center">
        <div
          v-if="changeRequest.status === 'CLOSED'"
          class="absolute right-full mr-2 h-2 w-2 rounded-full bg-primary-600 lg:mr-3"
        ></div>
        <UserAvatar variant="primary" :user="changeRequest.user" />
        <div class="min-w-0 pl-3 text-sm">
          <div class="truncate font-medium text-neutral-900">
            <span
              v-if="changeRequest.user.firstName && changeRequest.user.lastName"
              >{{ changeRequest.user.firstName }}
              {{ changeRequest.user.lastName }}
            </span>
            <span v-else>{{ changeRequest.user.email }}</span>
          </div>
          <div
            v-if="changeRequest.user.organisation"
            class="truncate text-neutral-400"
          >
            {{ changeRequest.user.organisation.name }}
          </div>
        </div>
      </div>
    </template>
    <template v-slot:colTwo>
      <div
        v-if="changeRequest.user.organisation"
        class="truncate flex items-center text-sm text-neutral-600"
      >
        <country-flag
          :country="changeRequest.user.organisation.country.code"
          size="small"
        />
        <span class="ml-2">{{ countryName }}</span>
      </div>
    </template>
    <template v-slot:colThree>
      <div class="truncate text-sm text-neutral-400">
        {{ formatDate(changeRequest.endDate) }}
      </div>
    </template>
    <template v-slot:colFour>
      <div class="truncate text-sm text-neutral-400">
        {{ $t("components.menus.dishProposal") }}
      </div>
    </template>
    <template v-slot:colFive>
      <div>
        <StatusLabel
          :status="changeRequest.status"
          :text="
            $t(`components.community.${changeRequest.status.toLowerCase()}`)
          "
        />
      </div>
    </template>
    <template v-slot:colSix>
      <div class="flex justify-end">
        <BaseButton
          v-if="changeRequest.status === 'CLOSED'"
          :text="$t(`components.menus.respond`)"
          size="small"
          variant="fill"
          @buttonClick="emitClick"
        >
        </BaseButton>
        <BaseButton
          v-if="changeRequest.status === 'ACCEPTED'"
          :text="$t(`components.menus.createDish`)"
          size="small"
          variant="outline"
          @buttonClick="redirectToCreateDish"
        >
        </BaseButton>
      </div>
    </template>
  </ChangeRequestsTableRowFrame>
</template>

<script>
import ChangeRequestsTableRowFrame from "@/components/menus/change-requests/ChangeRequestsTableRowFrame";
import UserAvatar from "@/components/common/UserAvatar";
import StatusLabel from "@/components/common/StatusLabel";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  components: {
    ChangeRequestsTableRowFrame,
    UserAvatar,
    StatusLabel,
  },
  props: {
    changeRequest: {
      type: Object,
      required: true,
    },
  },
  computed: {
    countryName() {
      return countries.getName(
        this.changeRequest.user.organisation.country.code,
        this.$i18n.locale,
      );
    },
  },
  methods: {
    formatDate(date) {
      const parsedDate = new Date(date);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(parsedDate);
    },
    redirectToCreateDish() {
      this.$router.push({
        name: "create-dish",
        params: {
          prefillBasicInformation: {
            name: this.changeRequest.name,
            description: this.changeRequest.description,
            courseUuid: this.changeRequest.course.uuid,
            coverImage: this.changeRequest.coverImage,
          },
        },
      });
    },
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>
