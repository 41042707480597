<template>
  <div class="flex items-center justify-between">
    <div class="text-sm">
      <div class="text-neutral-400">
        {{ title }}
      </div>
      <div class="font-medium text-neutral-900">
        {{ subtitle }}
      </div>
    </div>
    <div class="border flex h-1/2 rounded-sm border-neutral-100">
      <label
        class="border-r relative flex cursor-pointer items-center justify-center border-neutral-100 text-neutral-300 transition-all duration-200 hover:bg-neutral-0"
      >
        <input
          v-model="checkboxValue"
          type="radio"
          :name="`change-request-checklist-item-${id}`"
          :value="true"
          class="checkbox-approved pointer-events-none absolute opacity-0"
          @change="update"
        />
        <div
          class="flex h-10 w-10 items-center justify-center p-2 transition-all duration-200"
        >
          <IconTick />
        </div>
      </label>
      <label
        class="relative flex cursor-pointer items-center justify-center text-neutral-300 transition-all duration-200 hover:bg-neutral-0"
      >
        <input
          v-model="checkboxValue"
          type="radio"
          :name="`change-request-checklist-item-${id}`"
          :value="false"
          class="checkbox-declined pointer-events-none absolute opacity-0"
          @change="update"
        />
        <div
          class="flex h-10 w-10 items-center justify-center p-2 transition-all duration-200"
        >
          <IconClose />
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      // This is needed to catch the value as a boolean rather than a string
      checkboxValue: null,
    };
  },

  methods: {
    update() {
      this.$emit("input", this.checkboxValue);
    },
  },
};
</script>

<style scoped>
.checkbox-approved:checked + div {
  @apply bg-success-100 text-success-600;
}

.checkbox-declined:checked + div {
  @apply bg-danger-100 text-danger-600;
}
</style>
