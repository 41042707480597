<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>{{ $t("components.menus.dishProposal") }}</span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <div class="flex-grow overflow-y-scroll">
        <ModalSideContent>
          <div
            v-if="changeRequest.coverImage"
            class="relative mb-6 overflow-hidden rounded-sm bg-neutral-50 pt-1/2"
          >
            <img
              :src="changeRequest.coverImage.url"
              class="absolute top-0 left-0 h-full w-full object-cover"
            />
          </div>
          <div class="mb-2">
            <div class="text-sm text-neutral-400">
              {{ changeRequest.course.name }}
            </div>
            <div class="text-lg font-medium text-neutral-900">
              {{ changeRequest.name }}
            </div>
          </div>
          <div class="mb-6 text-sm text-neutral-600">
            {{ changeRequest.description }}
          </div>
          <div class="flex">
            <UserAvatar :user="changeRequest.user" class="mr-4" />
            <div class="flex flex-col justify-center text-sm">
              <div class="font-medium text-neutral-900">
                <span
                  v-if="
                    changeRequest.user.firstName && changeRequest.user.lastName
                  "
                >
                  {{ changeRequest.user.firstName }}
                  {{ changeRequest.user.lastName }}
                </span>
                <span v-else>{{ changeRequest.user.email }}</span>
              </div>
              <div
                v-if="changeRequest.user.organisation"
                class="text-neutral-600"
              >
                {{ changeRequest.user.organisation.name }}
              </div>
            </div>
          </div>
          <ProposalReview v-if="isReviewed" :proposal="changeRequest" />
        </ModalSideContent>

        <ModalSideContent
          v-if="changeRequest.status === 'CLOSED'"
          :default-padding="false"
          class="px-8 py-5"
        >
          <div>
            <ChangeRequestChecklistItem
              id="1"
              v-model="generalConceptApproved"
              :title="$t('components.menus.reviewModal.generalConceptApproved')"
              :subtitle="
                $t('components.menus.reviewModal.approvalPercentage', {
                  percentage: changeRequest.upvotePercentage,
                })
              "
            />
          </div>
        </ModalSideContent>
        <ModalSideContent
          v-if="changeRequest.status === 'CLOSED'"
          :default-padding="false"
          class="px-8 py-5"
        >
          <div>
            <ChangeRequestChecklistItem
              id="2"
              v-model="estimatedPriceApproved"
              :title="$t('components.menus.reviewModal.estimatedPriceApproved')"
              :subtitle="`€${changeRequest.estimatedPrice}`"
            />
          </div>
        </ModalSideContent>
        <ModalSideContent
          v-if="changeRequest.status === 'CLOSED'"
          :default-padding="false"
          class="px-8 py-5"
        >
          <div>
            <ChangeRequestChecklistItem
              id="3"
              v-model="productMixApproved"
              :title="$t('components.menus.reviewModal.productMixApproved')"
              :subtitle="`${changeRequest.productMix}%`"
            />
          </div>
        </ModalSideContent>
        <ModalSideContent
          v-if="changeRequest.status === 'CLOSED'"
          :default-padding="false"
          class="px-8 py-5"
        >
          <div>
            <ChangeRequestChecklistItem
              id="4"
              v-model="durationApproved"
              :title="$t('components.menus.reviewModal.durationApproved')"
              :subtitle="changeRequest.duration"
            />
          </div>
        </ModalSideContent>

        <ModalSideContent
          v-if="changeRequest.status === 'CLOSED'"
          class="flex-grow"
        >
          <ErrorAlert v-if="errorMessage" :text="errorMessage" class="mb-6" />

          <TextArea
            v-model="reviewText"
            :label="$t('components.menus.reviewModal.explainDecisionLabel')"
            :placeholder="$t('components.menus.reviewModal.typeSomething')"
          />
        </ModalSideContent>
      </div>

      <ModalSideActions
        v-if="changeRequest.status === 'CLOSED'"
        class="grid grid-flow-col gap-4"
      >
        <BaseButton
          :text="$t('components.menus.reviewModal.declineProposal')"
          size="large"
          variant="outline"
          :full-width="true"
          @buttonClick="submitReview('DECLINED')"
        >
          <template #iconLeft>
            <IconClose class="text-danger-600" />
          </template>
        </BaseButton>

        <BaseButton
          :text="$t('components.menus.reviewModal.approveProposal')"
          size="large"
          variant="outline"
          :full-width="true"
          @buttonClick="submitReview('ACCEPTED')"
        >
          <template #iconLeft>
            <IconCheckbox class="text-primary-600" />
          </template>
        </BaseButton>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";
import ModalSideActions from "@/components/common/ModalSideActions";
import UserAvatar from "@/components/common/UserAvatar";
import ErrorAlert from "@/components/common/ErrorAlert";
import ChangeRequestChecklistItem from "@/components/menus/change-requests/ChangeRequestChecklistItem";
import ProposalReview from "@/components/community/ProposalReview";

import { mapActions } from "vuex";

export default {
  components: {
    ModalSide,
    ModalSideContent,
    ModalSideActions,
    UserAvatar,
    ErrorAlert,
    ChangeRequestChecklistItem,
    ProposalReview,
  },

  props: {
    changeRequest: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      reviewText: "",
      estimatedPriceApproved: null,
      generalConceptApproved: null,
      productMixApproved: null,
      durationApproved: null,

      errorMessage: null,
    };
  },

  computed: {
    isReviewed() {
      return (
        this.changeRequest.status === "ACCEPTED" ||
        this.changeRequest.status === "DECLINED"
      );
    },
  },

  methods: {
    ...mapActions("changeRequests", ["reviewChangeRequest"]),

    closeModal() {
      this.$emit("close");
    },

    async submitReview(status) {
      if (this.reviewText.trim() === "") {
        this.errorMessage = this.$t(
          "components.menus.reviewModal.explainDecisionErrorMessage",
        );
        return;
      }

      this.errorMessage = null;

      await this.reviewChangeRequest({
        dishProposalUuid: this.changeRequest.uuid,
        status,
        text: this.reviewText,
        estimatedPriceApproved: this.estimatedPriceApproved,
        generalConceptApproved: this.generalConceptApproved,
        productMixApproved: this.productMixApproved,
        durationApproved: this.durationApproved,
      });

      this.closeModal();
    },
  },
};
</script>
