import { render, staticRenderFns } from "./ChangeRequestChecklistItem.vue?vue&type=template&id=fb8a5df6&scoped=true&"
import script from "./ChangeRequestChecklistItem.vue?vue&type=script&lang=js&"
export * from "./ChangeRequestChecklistItem.vue?vue&type=script&lang=js&"
import style0 from "./ChangeRequestChecklistItem.vue?vue&type=style&index=0&id=fb8a5df6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb8a5df6",
  null
  
)

export default component.exports